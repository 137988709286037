import React, { createContext, useEffect, Fragment, useState, useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import {
    BrowserRouter as Router,
    Link,
    NavLink,
    Routes,
    Route,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { useUserContext, UserContextProvider } from 'UserContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    solid,
    regular,
    light,
    brands,
    duotone,
} from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used
import {} from '@fortawesome/pro-solid-svg-icons';
import {
    faClock,
    faBadge,
    faUsers,
    faWallet,
    faRotateLeft,
    faGraduationCap,
    faFaceSmileHalo,
    faAddressCard,
    faEnvelope,
    faLock,
    faHatSanta,
} from '@fortawesome/pro-regular-svg-icons';
import {} from '@fortawesome/pro-light-svg-icons';
import {} from '@fortawesome/pro-thin-svg-icons';
import {} from '@fortawesome/pro-duotone-svg-icons';
import {} from '@fortawesome/free-brands-svg-icons';

import {
    useCreateUserWithEmailAndPassword,
    useSignInWithEmailAndPassword,
    useSendPasswordResetEmail,
} from 'react-firebase-hooks/auth';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import { collection, query, where, doc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { auth, firestore, functions } from 'Firebase.js';
import { signOut } from 'firebase/auth';

function HAPage() {
    function signout() {
        signOut(auth)
            .then(() => {
                // Sign-out successful.
            })
            .catch((error) => {
                // An error happened.
            });
    }
    return (
        <div className="flex flex-col justify-center items-center grow h-full py-4 px-6">
            <div className="text-4xl lg:text-6xl text-center font-extrabold tracking-wider bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 bg-clip-text text-transparent">
                Never
                <br />
                be too
                <br />
                predictable
            </div>
        </div>
    );
}

function HomePage() {
    function signout() {
        signOut(auth)
            .then(() => {
                // Sign-out successful.
            })
            .catch((error) => {
                // An error happened.
            });
    }
    return (
        <div className="flex flex-col justify-center items-center grow h-full py-4 px-6">
            <div className="text-4xl lg:text-6xl text-center font-extrabold tracking-wider bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 bg-clip-text text-transparent mt-24">
                ROGROUPTRADE
            </div>
            <div className="text-lg lg:text-2xl text-white pt-1 text-center font-semibold tracking-wider uppercase">
                #1 buyer network in Roblox
            </div>
            <div className="flex flex-col md:flex-row justify-center items-center pt-8 gap-6">
                <Link
                    to="/about"
                    className="px-0.5 py-0.5 font-medium tracking-wider bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 text-gray-100 rounded-full shadow-xl transition-all duration-300 hover:shadow-yellow-500/30 shadow-red-500/30"
                >
                    <div className="px-8 py-3 text-sm lg:text-base rounded-full bg-slate-900">
                        LEARN MORE
                    </div>
                </Link>
                <Link
                    to="/portal"
                    className="px-8 py-[0.825rem] text-sm lg:text-base font-medium tracking-wider bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 text-gray-100 rounded-full shadow-xl transition-all duration-300 hover:shadow-yellow-500/30 shadow-red-500/30"
                >
                    GET STARTED
                </Link>
            </div>
            <div className="flex flex-col gap-8 lg:flex-row lg:gap-32 px-24 mt-32 max-w-4xl text-gray-200">
                <div className="flex flex-col gap-1">
                    <div className="text-3xl text-center font-semibold tracking-wider uppercase ">
                        3.7K+
                    </div>
                    <div className="text-lg  text-center font-semibold tracking-wider uppercase text-pink-500">
                        GROUPS SOLD
                    </div>
                </div>
                <div className="flex flex-col gap-1">
                    <div className="text-3xl  text-center font-semibold tracking-wider uppercase">
                        2.9K+
                    </div>
                    <div className="text-lg  text-center font-semibold tracking-wider uppercase text-red-500">
                        BUYERS
                    </div>
                </div>
                <div className="flex flex-col gap-1">
                    <div className="text-3xl text-center font-semibold tracking-wider uppercase">
                        3.1K+
                    </div>
                    <div className="text-lg  text-center font-semibold tracking-wider uppercase text-yellow-500">
                        SELLERS
                    </div>
                </div>
            </div>
        </div>
    );
}

function AboutPage() {
    return (
        <div className="flex flex-col justify-center items-center grow h-full py-4 px-6 mb-24">
            <div className="text-4xl uppercase text-center font-extrabold tracking-wider bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 bg-clip-text text-transparent mt-24">
                The start
            </div>
            <div className="text-white pt-1 text-center tracking-wider max-w-3xl">
                There needed to be a perfect way to sell groups. Without a system in place, you
                could get scammed easily. That inspired this site, which was later updated with
                extra functionality. We have worked hard to become the most secure site for selling
                groups, and along the way, we have gotten a big community.
            </div>
            <div className="flex flex-col md:flex-row gap-8 mt-32 max-w-4xl text-gray-200">
                <div className="flex flex-col gap-1">
                    <div className="text-3xl  text-center font-semibold tracking-wider uppercase text-pink-500">
                        Privacy
                    </div>
                    <div className=" text-center tracking-wider ">
                        Your identity is secret. Everything gets done through untraceable accounts.
                        It ensures all proof of the purchase is gone and that your account remains
                        safe.
                    </div>
                </div>
                <div className="flex flex-col gap-1">
                    <div className="text-3xl  text-center font-semibold tracking-wider uppercase text-red-500">
                        Security
                    </div>
                    <div className="  text-center  tracking-wider">
                        No one goes first. Once both users complete their end of the deal, the
                        system will automatically complete the trade. And even better? The system is
                        fully encrypted.
                    </div>
                </div>
            </div>
            <div className="text-2xl mt-32  text-center font-semibold tracking-wider uppercase grid grid-cols-1 lg:grid-cols-2 gap-x-24 gap-y-12 text-gray-300">
                <span>
                    <span className="text-pink-500 text-3xl">1.</span> Create sale
                </span>
                <span>
                    <span className="text-pink-500 text-3xl">2.</span> Add details
                </span>
                <span>
                    <span className="text-red-500 text-3xl">3.</span> Recive bids
                </span>
                <span>
                    <span className="text-red-500 text-3xl">4.</span> Accept bid
                </span>
                <span>
                    <span className="text-yellow-500 text-3xl">5.</span> Submit group
                </span>
                <span>
                    <span className="text-yellow-500 text-3xl">6.</span> Get paid
                </span>
            </div>
            <div className="mt-32 mb-4 text-3xl  text-center font-semibold tracking-wider uppercase text-yellow-500">
                Q&A
            </div>
            <div className="font-semibold text-center text-lg tracking-wider text-gray-200">
                How can I bid?
            </div>
            <div className="mb-8 text-center  tracking-wider text-gray-200">
                Bidding is invite only. It is to ensure the quality of the bids. If you know a
                bidder, ask them to refer you! We monitor all bidders regularly, and we are
                constantly expanding.
            </div>
            <div className="font-semibold text-center text-lg tracking-wider text-gray-200">
                Who made the site?
            </div>
            <div className="mb-8 text-center  tracking-wider text-gray-200">
                We want to keep our identity secret, but we can tell it's two developers from the US
                and one from Poland.
            </div>
            <div className="font-semibold text-center text-lg tracking-wider text-gray-200">
                Is the site illegal?
            </div>
            <div className="mb-8 text-center  tracking-wider text-gray-200">
                No, you are not breaking any law by selling a group, but it is against Roblox's
                Terms of Use.
            </div>
            <div className="font-semibold text-center text-lg tracking-wider text-gray-200">
                Can you use USD?
            </div>
            <div className="mb-8 text-center  tracking-wider text-gray-200">
                The only currency supported is Robux. If we accepted USD, it would be traceable,
                which would ruin the point of the site. You can always convert the Robux to USD
                through the Roblox DevEx program.{' '}
                <a
                    className="underline decoration-blue-500 decoration-2"
                    href="https://en.help.roblox.com/hc/en-us/articles/203314100-Developer-Exchange-DevEx-FAQs"
                >
                    Read more about it here.
                </a>
            </div>
            <div className="font-semibold text-center text-lg tracking-wider text-gray-200">
                How does this site earn money? What's the catch?
            </div>
            <div className="mb-8 text-center  tracking-wider text-gray-200">
                Whenever a sale gets completed, we get a percentage of the tax that the buyer pays.
                You would need to pay tax either way, so this is at no additional cost to you.
            </div>
            <div className="font-semibold text-center text-lg tracking-wider text-gray-200">
                Why should I use the site?
            </div>
            <div className=" text-center  tracking-wider text-gray-200">
                It's far more secure, has better privacy, and gives you access to many buyers. It
                ensures that you get the most Robux when selling.
            </div>
        </div>
    );
}

function SigninPage() {
    const [user, loading, error] = useUserContext();
    const [signInWithEmailAndPassword, signInUser, signInLoading, signInError] =
        useSignInWithEmailAndPassword(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (!error && !loading && user) {
            navigate('/portal');
        }
    }, [user, loading, error]);

    function onSubmit(e) {
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;
        signInWithEmailAndPassword(email, password);
    }
    return (
        <div className="flex flex-col justify-center items-center grow h-full py-4 px-6">
            <form onSubmit={onSubmit} className="w-[20rem]">
                <div className="mb-3 block font-bold text-xl tracking-wider text-white">
                    Sign in
                </div>
                <label
                    htmlFor="email"
                    className="mb-2 block font-bold text-sm tracking-wider  text-gray-200"
                >
                    E-mail
                </label>
                <input
                    id="email"
                    name="email"
                    type="text"
                    className="block w-full appearance-none rounded-lg tracking-wider  bg-slate-700 px-5 py-3 text-sm text-white outline-none focus:bg-slate-600"
                    placeholder="example@mail.com"
                />
                <label
                    htmlFor="password"
                    className="mt-3 mb-2 block font-bold tracking-wider text-sm  text-gray-200"
                >
                    Password
                </label>
                <input
                    id="password"
                    name="password"
                    type="password"
                    className="block w-full appearance-none rounded-lg tracking-wider  bg-slate-700 px-5 py-3 text-sm text-white outline-none focus:bg-slate-600"
                    placeholder="* * * * * * * *"
                />

                <div className="flex flex-row mt-3 mb-3 justify-between">
                    <Link to="/signup" className="block tracking-wider text-sm text-gray-500">
                        New? Create user
                    </Link>
                    <Link
                        to="/password-reset"
                        className="block tracking-wider text-sm text-gray-500"
                    >
                        Forgot password?
                    </Link>
                </div>
                <button
                    type="submit"
                    className="mt-3 flex items-center gap-2 rounded-lg tracking-wider bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 px-5 py-2 text-sm font-medium text-white shadow-sm transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-white"
                >
                    Next
                </button>
                {signInLoading ? (
                    <FontAwesomeIcon
                        className="mt-4  text-blue-500"
                        size="lg"
                        icon={solid('circle-notch')}
                        spin
                    />
                ) : (
                    <></>
                )}
                {signInError ? (
                    <div className="mt-4 text-sm font-semibold text-red-600">
                        {signInError.code === 'auth/user-disabled'
                            ? 'This user is locked'
                            : signInError.code === 'auth/wrong-password'
                            ? 'Wrong password'
                            : signInError.code === 'auth/user-not-found'
                            ? 'User not found'
                            : signInError.code === 'auth/invalid-email'
                            ? 'Invalid e-mail'
                            : signInError.message}
                    </div>
                ) : (
                    <></>
                )}
            </form>
        </div>
    );
}

function SignupPage() {
    const [user, loading, error] = useUserContext();
    const [createUserWithEmailAndPassword, signInUser, signInLoading, signInError] =
        useCreateUserWithEmailAndPassword(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (!error && !loading && user) {
            navigate('/portal');
        }
    }, [user, loading, error]);

    function onSubmit(e) {
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;
        createUserWithEmailAndPassword(email, password);
    }
    return (
        <div className="flex flex-col justify-center items-center grow h-full py-4 px-6">
            <form onSubmit={onSubmit} className="w-[20rem]">
                <div className="mb-3 block font-bold text-xl tracking-wider text-white">
                    Sign up
                </div>
                <label
                    htmlFor="email"
                    className="mb-2 block font-bold text-sm tracking-wider  text-gray-200"
                >
                    E-mail
                </label>
                <input
                    id="email"
                    name="email"
                    type="text"
                    className="block w-full appearance-none rounded-lg tracking-wider  bg-slate-700 px-5 py-3 text-sm text-white outline-none focus:bg-slate-600"
                    placeholder="example@mail.com"
                />
                <label
                    htmlFor="password"
                    className="mt-3 mb-2 block font-bold tracking-wider text-sm  text-gray-200"
                >
                    Password
                </label>
                <input
                    id="password"
                    name="password"
                    type="password"
                    className="block w-full appearance-none rounded-lg tracking-wider  bg-slate-700 px-5 py-3 text-sm text-white outline-none focus:bg-slate-600"
                    placeholder="* * * * * * * *"
                />

                <div className="flex flex-row mt-3 mb-3 justify-between">
                    <Link to="/signin" className="block tracking-wider text-sm text-gray-500">
                        Sign in instead
                    </Link>
                </div>
                <button
                    type="submit"
                    className="mt-3 flex items-center gap-2 rounded-lg tracking-wider bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 px-5 py-2 text-sm font-medium text-white shadow-sm transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-white"
                >
                    Create
                </button>
                {signInLoading ? (
                    <FontAwesomeIcon
                        className="mt-4  text-blue-500"
                        size="lg"
                        icon={solid('circle-notch')}
                        spin
                    />
                ) : (
                    <></>
                )}
                {signInError ? (
                    <div className="mt-4 text-sm font-semibold text-red-600">
                        {signInError.code === 'auth/email-already-in-use'
                            ? 'E-mail already in use'
                            : signInError.code === 'auth/operation-not-allowed'
                            ? 'Operation not allowed'
                            : signInError.code === 'auth/weak-password'
                            ? 'The password is too weak'
                            : signInError.code === 'auth/invalid-email'
                            ? 'Invalid e-mail'
                            : signInError.message}
                    </div>
                ) : (
                    <></>
                )}
            </form>
        </div>
    );
}

function PasswordResetPage() {
    const [user, loading, error] = useUserContext();
    const [sendPasswordResetEmail, pwsending, pwerror] = useSendPasswordResetEmail(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (!error && !loading && user) {
            navigate('/portal');
        }
    }, [user, loading, error]);

    async function onSubmit(e) {
        e.preventDefault();
        const email = e.target.email.value;
        const success = await sendPasswordResetEmail(email);
        if (success) {
            alert('Password reset sent, check spam folder');
            navigate('/signin');
        }
    }
    return (
        <div className="flex flex-col justify-center items-center grow h-full py-4 px-6">
            <form onSubmit={onSubmit} className="w-[20rem]">
                <div className="mb-3 block font-bold text-xl tracking-wider text-white">
                    Password reset
                </div>
                <label
                    htmlFor="email"
                    className="mb-2 block font-bold text-sm tracking-wider  text-gray-200"
                >
                    E-mail
                </label>
                <input
                    id="email"
                    name="email"
                    type="text"
                    className="block w-full appearance-none rounded-lg tracking-wider  bg-slate-700 px-5 py-3 text-sm text-white outline-none focus:bg-slate-600"
                    placeholder="example@mail.com"
                />

                <div className="flex flex-row mt-3 mb-3 justify-between">
                    <Link to="/signin" className="block tracking-wider text-sm text-gray-500">
                        Sign in instead
                    </Link>
                </div>
                <button
                    type="submit"
                    className="mt-3 flex items-center gap-2 rounded-lg tracking-wider bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 px-5 py-2 text-sm font-medium text-white shadow-sm transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-white"
                >
                    Send reset e-mail
                </button>
                {pwsending ? (
                    <FontAwesomeIcon
                        className="mt-4  text-blue-500"
                        size="lg"
                        icon={solid('circle-notch')}
                        spin
                    />
                ) : (
                    <></>
                )}
                {pwerror ? (
                    <div className="mt-4 text-sm font-semibold text-red-600">
                        {pwerror.code === 'auth/user-not-found'
                            ? 'User not found'
                            : pwerror.code === 'auth/invalid-email'
                            ? 'Invalid e-mail'
                            : pwerror.message}
                    </div>
                ) : (
                    <></>
                )}
            </form>
        </div>
    );
}

function NewSalePage() {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    function onSubmit(e) {
        setLoading(true);
        e.preventDefault();
        var createSale = httpsCallable(functions, 'createSale');
        createSale({
            start: e.target.robux.value,
            id: e.target.group.value,
            description: e.target.description.value,
        }).then((result) => {
            if (result.data.success === false) {
                setError(result.data.error);
                setLoading(false);
            } else {
                console.log('/portal/sale/' + result.data.id);
                navigate('/portal/sale/' + result.data.id);
            }
        });
    }
    return (
        <div className="flex flex-col justify-center items-center grow h-full py-4 px-6">
            <form onSubmit={onSubmit} className="w-[20rem] max-w-full">
                <div className="mb-3 block font-bold text-xl tracking-wider text-white">
                    Create new sale
                </div>
                <label
                    htmlFor="group"
                    className="mb-2 block font-bold text-sm tracking-wider  text-gray-200"
                >
                    Group ID
                </label>
                <input
                    id="group"
                    name="group"
                    type="text"
                    className="block w-full appearance-none rounded-lg tracking-wider  bg-slate-700 px-5 py-3 text-sm text-white outline-none focus:bg-slate-600"
                    placeholder="ID"
                />
                <label
                    htmlFor="robux"
                    className="mb-2 mt-2 block font-bold text-sm tracking-wider  text-gray-200"
                >
                    Start price (in Robux)
                </label>
                <input
                    id="robux"
                    name="robux"
                    type="number"
                    className="block w-full appearance-none rounded-lg tracking-wider  bg-slate-700 px-5 py-3 text-sm text-white outline-none focus:bg-slate-600"
                    placeholder="Robux"
                />
                <label
                    htmlFor="description"
                    className="mb-2 mt-2 block font-bold text-sm tracking-wider  text-gray-200"
                >
                    Description
                </label>
                <textarea
                    id="description"
                    name="description"
                    className="block w-full appearance-none rounded-lg tracking-wider h-32 bg-slate-700 px-5 py-3 text-sm text-white outline-none focus:bg-slate-600"
                    placeholder="Write details about the group, what would a buyer be intrested in knowing?"
                ></textarea>

                <button className="max-w-max mt-3 flex items-center gap-2 rounded-lg tracking-wider bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 px-5 py-2 text-sm font-medium text-white shadow-sm transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-white">
                    Start
                </button>

                {loading ? (
                    <FontAwesomeIcon
                        className="mt-4  text-blue-500"
                        size="lg"
                        icon={solid('circle-notch')}
                        spin
                    />
                ) : (
                    <></>
                )}

                {error ? (
                    <div className="mt-4 text-sm font-semibold text-red-600">{error}</div>
                ) : (
                    <></>
                )}
            </form>
        </div>
    );
}

function OverviewPage() {
    const [user, userloading, usererror] = useUserContext();
    const [uid, setuid] = useState('***');
    const [value, loading, error] = useCollection(
        query(collection(firestore, 'sales'), where('owner', '==', uid)),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    useEffect(() => {
        if (!error && !loading && user) {
            setuid(user.uid);
        }
    }, [user, loading, error]);

    return (
        <div className="flex flex-col justify-center items-center grow h-full py-4 px-6">
            {error && <strong>Error: {JSON.stringify(error)}</strong>}
            {loading && <span className="text-white">Loading...</span>}
            {value && (
                <div className="w-[30rem] max-w-full flex flex-col justify-center items-center">
                    <div className="mb-3 block font-bold text-center text-xl tracking-wider text-white">
                        Sales
                    </div>
                    {value.size === 0 ? (
                        <div className="text-white text-center">
                            You haven't created a sale, click on the button to add a new sale!
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="flex flex-col gap-4 w-full max-w-full">
                        {value.docs.map((doc) => (
                            <Link
                                to={'/portal/sale/' + doc.id}
                                key={doc.id}
                                className="bg-slate-700 w-full rounded-xl py-4 px-5"
                            >
                                <div className="text-white  font-semibold">{doc.data().name}</div>
                                <div className="text-white">ID: {doc.data().group_id}</div>
                                <div className="text-white">Status: {doc.data().status}</div>
                            </Link>
                        ))}
                    </div>
                    <Link
                        to="/portal/new"
                        className="mt-4 max-w-max flex items-center gap-2 rounded-lg tracking-wider bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 px-5 py-2 text-sm font-medium text-white shadow-sm transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-white"
                    >
                        Add new sale
                    </Link>
                </div>
            )}
        </div>
    );
}

function SalePage() {
    const [aerror, setError] = useState('');
    const [aloading, setLoading] = useState(false);
    const { saleId } = useParams();
    const [value, loading, error] = useDocument(doc(firestore, 'sales', saleId), {
        snapshotListenOptions: { includeMetadataChanges: true },
    });
    function onSubmitShirt(e) {
        e.preventDefault();
        setLoading(true);
        setError('');
        var submitAsset = httpsCallable(functions, 'submitAsset');
        submitAsset({
            sale: saleId,
            asset_id: e.target.shirt.value,
        }).then((result) => {
            if (result.data.success === false) {
                setLoading(false);
                setError(result.data.error);
            } else {
                setLoading(false);
            }
        });
    }
    function onSubmitGroup(e) {
        e.preventDefault();
        setLoading(true);
        setError('');
        var submitGroup = httpsCallable(functions, 'submitGroup');
        submitGroup({
            sale: saleId,
        }).then((result) => {
            if (result.data.success === false) {
                setLoading(false);
                setError(result.data.error);
            } else {
                setLoading(false);
            }
        });
    }
    function doPause(pstate) {
        var togglePaused = httpsCallable(functions, 'togglePaused');
        togglePaused({
            sale: saleId,
            paused: pstate,
        }).then((result) => {
            if (result.data.success === false) {
                alert('Error: ' + result.data.error);
            } else {
            }
        });
    }
    function acceptBid() {
        var acceptBid = httpsCallable(functions, 'acceptBid');
        acceptBid({
            sale: saleId,
        }).then((result) => {
            if (result.data.success === false) {
                alert('Error: ' + result.data.error);
            } else {
            }
        });
    }
    return (
        <div className="flex flex-col justify-center items-center grow h-full py-4 px-6">
            {error && <strong className="text-white">Error: {JSON.stringify(error)}</strong>}
            {loading && <span className="text-white">Loading...</span>}
            {value && (
                <div className="w-[30rem] max-w-full flex flex-col justify-center items-center">
                    <div className="mb-1.5 block font-bold text-center text-xl tracking-wider text-white">
                        {value.data().name}
                    </div>
                    <div className="text-white text-center mb-1">{value.data().group_id}</div>
                    <div className="text-white text-center mb-3">Status: {value.data().status}</div>

                    {value.data().complete === false && value.data().first_bid === true ? (
                        <div className="mb-4 flex flex-row gap-3">
                            <button
                                onClick={() => doPause(!value.data().paused)}
                                className="max-w-max flex items-center gap-2 rounded-lg tracking-wider bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 px-5 py-2 text-sm font-medium text-white shadow-sm transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-white"
                            >
                                {value.data().paused ? 'Resume' : 'Pause'} bidding
                            </button>
                            <button
                                onClick={() => acceptBid()}
                                className="max-w-max flex items-center gap-2 rounded-lg tracking-wider bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 px-5 py-2 text-sm font-medium text-white shadow-sm transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-white"
                            >
                                Accept highest bid
                            </button>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div>
                        {value.data().complete === true ? (
                            <>
                                <div className="text-white text-center text-xl font-bold mb-1">
                                    Winner: Bidder{' '}
                                    {value.data().bids[value.data().bids.length - 1].name}
                                </div>
                                <div className="text-green-500 text-center mb-3">
                                    {value.data().bids[value.data().bids.length - 1].amount} Robux
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                        {value.data().complete === true && value.data().asset_provided === false ? (
                            <form onSubmit={onSubmitShirt} className="w-[20rem] max-w-full">
                                <div className="text-white text-center mb-3">
                                    Create a shirt and set the price to{' '}
                                    {value.data().bids[value.data().bids.length - 1].amount} Robux,
                                    submit the ID
                                </div>
                                <label
                                    htmlFor="shirt"
                                    className="mb-2 block font-bold text-sm tracking-wider  text-gray-200"
                                >
                                    Shirt ID
                                </label>
                                <input
                                    id="shirt"
                                    name="shirt"
                                    type="text"
                                    className="block w-full appearance-none rounded-lg tracking-wider  bg-slate-700 px-5 py-3 text-sm text-white outline-none focus:bg-slate-600"
                                    placeholder="ID"
                                />

                                <button className="max-w-max mt-3 flex items-center gap-2 rounded-lg tracking-wider bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 px-5 py-2 text-sm font-medium text-white shadow-sm transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-white">
                                    Submit
                                </button>
                                {aloading ? (
                                    <FontAwesomeIcon
                                        className="mt-4  text-blue-500"
                                        size="lg"
                                        icon={solid('circle-notch')}
                                        spin
                                    />
                                ) : (
                                    <></>
                                )}

                                {aerror ? (
                                    <div className="mt-4 text-sm font-semibold text-red-600">
                                        {aerror}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </form>
                        ) : (
                            <></>
                        )}
                        {value.data().complete === true &&
                        value.data().asset_provided === true &&
                        value.data().transfered === false ? (
                            <form onSubmit={onSubmitGroup} className="w-[20rem] max-w-full">
                                <div className="text-white text-center mb-3">
                                    Give group ownership to the bot in order to start the transfer
                                </div>
                                <label
                                    htmlFor="shirt"
                                    className="mb-2 block font-bold text-sm tracking-wider  text-gray-200"
                                >
                                    Bot username
                                </label>
                                <div className="bg-slate-700 w-full rounded-xl py-4 px-5">
                                    <div className="text-white text-sm">
                                        {value.data().transfername}
                                    </div>
                                </div>
                                <button className="max-w-max mt-3 flex items-center gap-2 rounded-lg tracking-wider bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 px-5 py-2 text-sm font-medium text-white shadow-sm transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-white">
                                    Done
                                </button>
                                {aloading ? (
                                    <FontAwesomeIcon
                                        className="mt-4  text-blue-500"
                                        size="lg"
                                        icon={solid('circle-notch')}
                                        spin
                                    />
                                ) : (
                                    <></>
                                )}

                                {aerror ? (
                                    <div className="mt-4 text-sm font-semibold text-red-600">
                                        {aerror}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </form>
                        ) : (
                            <></>
                        )}
                    </div>
                    {value.data().complete === false ? (
                        <div className="flex flex-col gap-4  w-[20rem] max-w-full">
                            {value.data().bids.length === 0 ? (
                                <div className="text-white text-center font-semibold text-lg uppercase">
                                    No bids yet
                                </div>
                            ) : (
                                <></>
                            )}
                            {value.data().bids.map((bid) => (
                                <div className="bg-slate-700 w-full rounded-xl py-4 px-5">
                                    <div className="text-white  font-semibold">
                                        Bidder {bid.name}
                                    </div>
                                    <div className="text-white">Bid: {bid.amount} Robux</div>
                                    <div className="text-white">
                                        Date: {bid.time.toDate().toLocaleString()}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            )}
        </div>
    );
}

function PortalPage() {
    const [user, loading, error] = useUserContext();

    if (loading) {
        return (
            <div className="flex grow flex-col justify-center gap-1">
                <div className="text-center">
                    <svg
                        role="status"
                        className="mr-2 inline h-10 w-10 animate-spin fill-blue-600 text-white"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                        />
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                        />
                    </svg>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div>
                <p>User auth error</p>
            </div>
        );
    }

    if (!user) {
        return <SigninPage />;
    }

    return (
        <Routes>
            <Route path="/" element={<OverviewPage />} />
            <Route path="/new/*" element={<NewSalePage />} />
            <Route exact path="/sale/:saleId/*" element={<SalePage />} />
        </Routes>
    );
}

function App() {
    useEffect(() => {
        function setViewHeight() {
            const { innerHeight: height } = window;
            document.documentElement.style.setProperty('--screenfull', `${Math.floor(height)}px`);
        }

        setViewHeight();

        window.addEventListener('resize', setViewHeight);
    }, []);

    return (
        <div className="px-2 py-2 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 min-h-screen">
            <UserContextProvider>
                <div className="bg-slate-900 rounded-3xl overflow-hidden min-h-insidescreen flex flex-col justify-between">
                    <div className="bg-slate-900 min-h-insidescreen h-max flex flex-col">
                        <HAPage />
                    </div>
                </div>
            </UserContextProvider>
        </div>
    );
}

export default App;
