import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyCY8HwSNSMVNhcM4NmAUXPgunHlMLFcv8E',
    authDomain: 'grouptrade-9a001.firebaseapp.com',
    projectId: 'grouptrade-9a001',
    storageBucket: 'grouptrade-9a001.appspot.com',
    messagingSenderId: '766358947834',
    appId: '1:766358947834:web:b4024c29a56165bf9cc643',
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);

export const firestore = getFirestore();
export const auth = getAuth();
export const functions = getFunctions();
export default firebase;
