import React, { createContext, useState, useEffect, useContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'Firebase.js';

// create context
const UserContext = createContext();

const UserContextProvider = ({ children }) => {
    // the value that will be given to the context
    const [authUser, authLoading, authError] = useAuthState(auth);

    const [user, setUser] = useState({
        user: null,
        loading: true,
        error: undefined,
    });

    // fetch a user from a fake backend API
    useEffect(() => {
        setUser({ user: authUser, loading: authLoading, error: authError });
    }, [authUser, authLoading, authError]);

    return (
        // the Provider gives access to the context to its children
        <UserContext.Provider value={user}>{children}</UserContext.Provider>
    );
};

const useUserContext = () => {
    // get the context
    const context = useContext(UserContext);

    // if `undefined`, throw an error
    if (context === undefined) {
        throw new Error('useUserContext was used outside of its Provider');
    }

    return [context.user, context.loading, context.error];
};

export { UserContext, useUserContext, UserContextProvider };
